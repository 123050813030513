import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, signInWithEmailAndPassword, signInWithGoogle } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import "./Login.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/dashboard");
  }, [user, loading]);
  return (
    <div className="login">
      <Card>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={"logo.svg"} className="login_logo" alt="React Logo" />
          <Button variant="contained" sx={{ m: 1 }} onClick={signInWithGoogle}>
            Sign in with Google
          </Button>
          <Link
            sx={{ m: 1 }}
            href="https://play.google.com/store/apps/details?id=com.brinkhorizon.socials"
          >
            Dont have an account? Download the Cartera app on the Play Store to
            get started
          </Link>
        </CardContent>
      </Card>
    </div>
  );
}

export default Login;
